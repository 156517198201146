import { graphql, PageProps } from 'gatsby';
import React, { useContext, useMemo } from 'react';
import ColouredPageSection from '../components/ColouredPageSection';
import Section from '../components/formatting/Section';
import WithHeroLayout from '../layouts/WithHeroLayout';
import SectionDescription from '../components/formatting/SectionDescription';
import { Link } from '@reach/router';
import generateSlug from '../util/generateSlug';
import styled from 'styled-components';
import { groupBy, orderBy } from 'lodash';
import moment from 'moment';
import SectionTitle from '../components/formatting/SectionTitle';
import SiteConfigContext from '../context/SiteConfigContext';
import EventCompact from '../components/EventCompact';

const StyledEvent = styled(EventCompact)``;

const EventsList = styled.div`
  ${StyledEvent} {
    margin-bottom: 16px;
  }
`;

interface Props extends PageProps {
  data: {
    event_category: App.MDXType<App.EventCategory>;
    events: App.GraphQLConnection<App.MDXType<App.Event>>;
  };
}

const EventCategoryArchiveTemplate: React.FC<Props> = function (props) {
  const { data, location } = props;

  const { frontmatter } = data.event_category.childMdx;
  const { label, content, hero_image, color } = frontmatter;
  const { edges: events } = data.events;

  const siteCfg = useContext(SiteConfigContext);

  const {
    block_description = '',
    page_description = '',
    past_events_description = '',
  } = siteCfg?.events_past_projects_configuration ?? {};

  const eventsGrouped = useMemo(
    () =>
      groupBy(events, edge =>
        moment(
          edge.node.childMdx.frontmatter.date_ranges?.[0]?.start_date
        ).year()
      ),
    [events]
  );

  const eventsSortedYear = useMemo(() => {
    const entries = Object.entries(eventsGrouped);

    return orderBy(entries, entry => Number(entry[0]), 'desc');
  }, [eventsGrouped]);

  return (
    <WithHeroLayout
      pageTitle={label}
      bgImgSrc={hero_image}
      color={color}
      path={location?.pathname}
    >
      <Section>
        <SectionDescription>
          {page_description.replace('$category', label)}
        </SectionDescription>
      </Section>
      <ColouredPageSection
        firstColor={color}
        title="Past Events"
        sectionDescription={past_events_description.replace('$category', label)}
      >
        <EventsList>
          {eventsSortedYear.map(([year, events]) => (
            <>
              <SectionTitle>{year}</SectionTitle>
              {orderBy(
                events,
                'node.childMdx.frontmatter[0].start_date',
                'desc'
              ).map(edge => (
                <Link
                  key={edge.node.id}
                  to={`/event/${generateSlug(
                    edge.node.childMdx.frontmatter.title
                  )}`}
                >
                  <StyledEvent event={edge.node.childMdx.frontmatter} />
                </Link>
              ))}
            </>
          ))}
        </EventsList>
      </ColouredPageSection>
    </WithHeroLayout>
  );
};

export const query = graphql`
  query($absolutePath: String!, $name: String!) {
    event_category: file(absolutePath: { eq: $absolutePath }) {
      childMdx {
        body
        frontmatter {
          label
          content
          hero_image
          color
          type
        }
      }
    }

    events: allFile(
      filter: {
        sourceInstanceName: { eq: "event" }
        childMdx: { frontmatter: { categories: { in: [$name] } } }
      }
    ) {
      edges {
        node {
          id
          childMdx {
            frontmatter {
              title
              date_ranges {
                start_date
                end_date
              }
              content
              excerpt
            }
          }
        }
      }
    }
  }
`;

export default EventCategoryArchiveTemplate;
